.connect-inbox-steps-list{
  &{
    list-style: none;
    font-size: 16px;
    line-height: 25px;
    padding-left: 0;
  }
  li{
    padding-bottom: 15px;
    padding-top: 15px;
  }
}


.progress-automated-test-three{
  li{
    &{
      width: 32%!important;
    }
  }
}

.progress-automated-test-two{
  li,a{
    &{
      width: 49%!important;
    }
  }
}

.progress-automated-test-gmail{
  li{
    &{
      width: 23.4%!important;
    }
  }
}


.connect-v2{
  .progress-automated-test.progress-provider-connection{
    &{
      position: initial!important;
      float: left;
      width: 100%;
      height: 30px;
      margin-bottom: -18px;
      margin-top: -5px;
      padding-top: 13px;
    }
  }
}


.nopadding-right-gmail{
  &{
    padding-right: 6px;
  }
}

iframe.gmail-guide{
  &{
    width: 100%;
    height: 258px;
    margin-top: 5px;
    border-radius: 10px;
  }
}


.connect-v2{
  .title-v2{
    &{
      margin-bottom: 57px;
      float: left;
    }
  }
  .desc-outlook-form{
    &{
      margin-top: 8px;
      margin-bottom: 13px;
    }
  }

  .need-admin-approval{
    &{
      text-align: center;
      max-width: 323px;
      margin: 0 auto;
      margin-bottom: -7px!important;
    }
    .fa-solid{
      &{
        margin-top: 0px;
        margin-left: 2px;
        margin-right: 8px;
      }
    }
    span{
      &{
        width: 91%;
      }
    }
  }
}


.cov2{
  &{

  }
  &.provider .card{
    &{
      width: 48%;
    }
  }
}

.first-form-padding{
  &{
    padding-top: 15px;
    padding-bottom: 11px;
  }
}


.instructions{
  &{
    padding: 16px;
    padding-bottom: 27px;
  }
}

.imap{
  select{
    &{
      padding: 13px;
    }
  }
}

.fieldCont-new-margn{
  &{
    margin-bottom: 32px!important;
  }
}


.selected-provider.provider{
  &{
    margin-bottom: 60px;
    margin-top: -10px;
  }

  .config-ico{
    &{
      margin-top: 0px;
      margin-left: 4px;
    }
  }

  .card{
    &{
      box-shadow: none;
      background: transparent;
      width: 75px;
      margin-left: 0;
      margin-right: 0;
    }
    .img{
      &{
        box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        background: #fff;
        border-radius: 20%;
        max-width: 45px;
        background: #fff;
      }
    }
  }
}

.card-gmail{
  &{
    height: 372px;
  }
}

.big-container-no-margin{
  &{
    padding: 0;
  }
}

.selected-provider.provider .card{
  &{
    margin-left: 46% !important;
    cursor: default;
  }

  &.selected{
        border: 2px solid #fff;
  }
}

.providers-list-back-btn{
  &{
    float: left;
    position: absolute;
    margin-top: 51px;
    opacity: 0.7;
    cursor: pointer;
  }
  &:hover{
    text-decoration: underline;
  }
}

.col-md-12{
  .full-width{
    &{
      width: 100%;
    }
  }
}


.connect-v2{
  h3.text-center{
    margin-bottom: 45px;
    margin-top: 45px;
  }

  h2.text-center{
    text-align: center;
    float: none!important;
  }
}

.desc-text{
  &{
    font-size: 15px;
    margin-top: 18px!important;
  }
}


.step-one-gmail{
  .card-gmail{
    &{
      height: 352px;
    }
    ul{
      li:first-child{
        &{
          padding-top: 11px;
        }
      }
    }
  }
}

.connect-workspace{
  &{

  }


  .imap{
    .fieldCont{
      margin-top: 18px;
    }
  }
  .title-workspace-linked{
    &{
      margin-top: 8px;
      margin-bottom: 13px;
      float: left;
    }
  }
  .list-infos{
    &{
      list-style: none;
      padding: 0;
      padding-left: 20px;
      padding-top: 10px;
      line-height: 30px;
      font-size: 16px;
    }
  }
  .upload-block{
    &{
      position: relative;
      padding: 5px;
      margin-top: 0px;
    }

    .fa-cloud-upload{
      &{
        font-size: 25px;
        margin-bottom: 10px;
      }
    }
  }

  .file-upload-zone{
    &{
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 2px;
      border-radius: 1rem;
      border-style: dashed;
      border-color: #cdcbe1;
      background-color: #f9f8fc;
      height: 200px;
      margin-top: 12px;
      margin-bottom: 18px;
      cursor: pointer;
    }
  }

  .drag-file-element{
    &{
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  .guide-txt{
    &{
      font-size: 18px;
      padding-top: 16px;
    }
  }

  .workspaces-list{
    &{
      padding: 0;
    }
    li{
      &{
        list-style: none;
        //border-bottom: 1px solid #ccc;
        width: 650px;
        padding: 0 11px;
        margin-top: 10px;
        cursor: pointer;
        border: 1px solid #e1e1e1;
        border-radius: 8px;
        padding: 7px;
        height: 80px;
        margin-bottom: 16px;
        padding-left: 16px;
      }
      &:hover{
        border: 1px solid #c2c2c2;
      }
      &.selected{
        border: 1px solid #3d46fb;
      }
      &.add{
        border: 1px dashed #e1e1e1;
      }
      &.add:hover{
        border: 1px dashed #c2c2c2;
      }
      b{
        margin-top: 11px;
        float: left;
      }
      &.add{
        b{
          margin-top: 22px;
        }
      }
      .line{
        &{
          width: 100%;
          float: left;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      .createdAt{
        &{
          margin-top: -6px;
          float: left;
          opacity: 0.8;
        }
      }
      .edit-btn{
        font-size: 12px;
        color: #ccc;
        margin: 10px;
        margin-top: 12px;
        float: left;
      }
      .unselectedBtn{
        &{
          background-color: #fff!important;
          color: #3d46fb !important;
          border: 1px solid #3d46fb !important;
        }
      }
      .btn-secondary{
        &{
          margin-right: 10px;
        }
      }
    }
  }

  .block-textarea-emails{
    &{
      padding-top: 17px;
    }
    p{
      &{
        margin: 0;
      }
    }
  }

  .styled-input{
    &{
      margin: 0;
      width: 100%;
      padding: 12px;
      font-size: 16px;
      color: #18164c;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e0e5e9;
      border-radius: 4px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  textarea.emails-field{
    &{
      width: 100%;
      height: 300px;
      border-radius: 8px;
      border-color: #ccc;
      font-size: 15px;
      padding: 11px;
      margin-top: 17px;
      margin-bottom: 10px;
    }
  }

}

.progress-error-modal-workspace{
  .fa-times-circle{
    &{
      color: #ff1d51;
      cursor: pointer;
    }
  }
  ul{
    li{
      &{
        list-style: none;
      }
      .status{
        &{
          float: right;
        }
      }
    }
  }
}


.automated-test-create{
  .progress-provider-connection{
    &{
      display: none;
    }
  }
}
